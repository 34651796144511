import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import {
  DialogState,
  DialogType,
} from '../../ViewModel/dialogViewModel/dialogViewModel';
import { getTrackingInfoForCalendarNextButtonClick } from '@wix/bookings-analytics-adapter';
import { bookingsContactInfoSaveSuccess } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  mapFlowTypeToBiProperties,
  mapStateToProperties,
} from '../../../../utils/bi/mappers';
import {
  applicableForPayments,
  isBookingsEnabled,
} from '../../../../utils/serviceUtils/serviceUtils';
import { DynamicPriceInfo, Service } from '@wix/bookings-uou-types';
import { getDynamicPricingInfo } from '../../../../utils/dynamicPricing/dynamicPricing';
import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { isSlotNoBookFlow } from '../../../../utils/slotAvailability/slotAvailability';
import { CalendarStatus } from '../../ViewModel/widgetViewModel/widgetViewModel';

export type NavigateToNextPage = ({
  selectedSlots,
  isWaitingListFlow,
}: {
  selectedSlots: SlotAvailability[];
  isWaitingListFlow: boolean;
}) => void;

export function createNavigateToNextPageAction(
  actionFactoryParams: ActionFactoryParams<CalendarState, CalendarContext>,
): NavigateToNextPage {
  return async ({ selectedSlots, isWaitingListFlow }) => {
    const { getControllerState, context } = actionFactoryParams;
    const [state, setState] = getControllerState();
    const { wixSdkAdapter, biLogger, activeFeatures, preset, experiments } =
      context;
    const { availableServices, selectedTime, rescheduleBookingDetails } = state;

    const serviceId = selectedSlots[0].slot?.serviceId;
    const selectedService = availableServices.find(
      (service) => service.id === serviceId,
    )!;

    const isTemplateMode = wixSdkAdapter.isTemplateMode();

    const isDayfulMovePremiumBlockModalEnabled = experiments.enabled(
      'specs.bookings.DayfulMovePremiumBlockModal',
    );
    const isApplicableForPayments =
      !isDayfulMovePremiumBlockModalEnabled && context.isDayful
        ? await applicableForPayments(selectedService.payment, wixSdkAdapter)
        : true;
    const isBookingEnabled =
      isBookingsEnabled({
        serviceType: selectedService.info.type,
        activeFeatures: activeFeatures!,
        isTemplateMode,
      }) && isApplicableForPayments;

    if (isBookingEnabled) {
      const slotAvailability = selectedSlots[0];
      const isNoBookFlow = isSlotNoBookFlow(slotAvailability);
      const isReschedulingFlow = !!rescheduleBookingDetails;
      const isBookingFormInstalled =
        await wixSdkAdapter.isBookingFormInstalled();

      void biLogger.report(
        bookingsContactInfoSaveSuccess({
          selectedSlot: selectedTime,
          ...mapFlowTypeToBiProperties({
            isWaitingList: isWaitingListFlow,
            isBookingFormInstalled,
            isNoBookFlow,
            properties: mapStateToProperties({ state, context }),
          }),
        }),
      );

      if (isNoBookFlow) {
        handleNoBookFlow({
          selectedService,
          context,
        });
      } else if (isReschedulingFlow) {
        handleReschedulingFlow(actionFactoryParams);
      } else if (isWaitingListFlow) {
        handleWaitingListFlow(actionFactoryParams);
      } else {
        handleRegularBookFlow(
          actionFactoryParams,
          slotAvailability,
          selectedService,
        );
      }
    } else {
      if (wixSdkAdapter.isPreviewMode()) {
        const serviceType = selectedService.info.type;
        await wixSdkAdapter.openPreviewPremiumModal(serviceType, preset);
      }

      if (wixSdkAdapter.isSiteMode()) {
        setState({
          dialog: {
            type: DialogType.PremiumViewer,
            state: DialogState.IDLE,
          },
        });
      }
    }
  };
}

const handleWaitingListFlow = ({
  getControllerState,
}: ActionFactoryParams<CalendarState, CalendarContext>) => {
  const [, setState] = getControllerState();

  setState({
    dialog: {
      type: DialogType.JoinWaitlist,
      state: DialogState.IDLE,
    },
  });
};

const handleReschedulingFlow = ({
  getControllerState,
}: ActionFactoryParams<CalendarState, CalendarContext>) => {
  const [, setState] = getControllerState();

  setState({
    dialog: {
      type: DialogType.RescheduleConfirm,
      state: DialogState.IDLE,
    },
  });
};

const handleNoBookFlow = ({
  selectedService,
  context,
}: {
  selectedService: Service;
  context: CalendarContext;
}) => {
  const { preset: referral, wixSdkAdapter, experiments } = context;
  const isMultipleSlugsEnabled = experiments.enabled(
    'specs.bookings.multipleServiceSlugs',
  );
  const serviceSlug = isMultipleSlugsEnabled
    ? selectedService!.info.slugs?.[0]!
    : selectedService.info.slug!;

  void wixSdkAdapter.navigateToBookingsServicePage(serviceSlug, {
    referral,
  });
};

const handleRegularBookFlow = (
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  slotAvailability: SlotAvailability,
  selectedService: Service,
) => {
  const { wixSdkAdapter, businessInfo, experiments, preset, isCartEnabled } =
    context;
  const [state, setState] = getControllerState();
  const { selectedTimezone, selectedVariantsOptions } = state;
  const isDynamicPricingUoUEnabled = experiments.enabled(
    'specs.bookings.dynamicPricingUoU',
  );
  const isNavigationLoaderEnabled = experiments.enabled(
    'specs.bookings.calendar.navigationLoader',
  );
  const isMultipleSlugsEnabled = experiments.enabled(
    'specs.bookings.multipleServiceSlugs',
  );

  const continueShoppingUrl = isCartEnabled
    ? `/${wixSdkAdapter.getCurrentPath().join('/')}?${
        BookingsQueryParams.TIMEZONE
      }=${state.selectedTimezone}`
    : undefined;

  const isAnywherePublicDataOverridesEnabled =
    experiments &&
    experiments.enabled('specs.bookings.anywherePublicDataOverrides');

  const serviceSlug = isMultipleSlugsEnabled
    ? selectedService!.info.slugs?.[0]!
    : selectedService.info.slug!;

  const trackingInfo = getTrackingInfoForCalendarNextButtonClick({
    services: [selectedService],
    businessName: businessInfo?.name || '',
  });
  wixSdkAdapter.trackAnalytics(trackingInfo);
  let dynamicPriceInfo: DynamicPriceInfo | undefined;
  if (isDynamicPricingUoUEnabled) {
    dynamicPriceInfo = getDynamicPricingInfo(selectedVariantsOptions);
  }

  if (isNavigationLoaderEnabled) {
    setState(
      { calendarStatus: CalendarStatus.NAVIGATING },
      { immediate: true },
    );
  }
  void wixSdkAdapter.navigateToBookingsFormPage({
    slotAvailability,
    serviceId: selectedService.id,
    serviceSlug,
    dynamicPriceInfo,
    isAnywhereSpecEnable: isAnywherePublicDataOverridesEnabled,
    queryParams: {
      [BookingsQueryParams.REFERRAL]: preset,
      [BookingsQueryParams.TIMEZONE]: selectedTimezone!,
      ...(isCartEnabled
        ? { [BookingsQueryParams.CONTINUE_SHOPPING_URL]: continueShoppingUrl }
        : {}),
    },
  });
};
